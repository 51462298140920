<template>
  <BaseModal
    ref="modal"
    :title="editMode ? 'Medewerker bewerken' : 'Medewerker toevoegen'"
  >
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="entityContactsCreateUpdateModalForm"
      @submit="submit"
    >
      <FormulateInput
        type="autocomplete"
        autoCompleteType="collaborator"
        name="collaborator"
        label="Medewerker"
        placeholder="Selecteer een medewerker"
        validation="required"
        :disabled="editMode"
      />
      <FormulateInput
        type="select"
        name="role"
        label="Rol"
        :options="roles"
        placeholder="Selecteer een rol"
        validation="required"
      />
      <FormulateInput
        v-if="propertyIsRental"
        type="checkbox"
        name="show_phone_number"
        label="Telefoonnummer is zichtbaar"
      />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        title="Opslaan"
        outer-class="tw-my-4 tw-float-right"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { successModal } from '@/modalMessages'
import { getCollaboratorRoles } from '@/services/organization'
import {
  createProjectCollaborator,
  updateProjectCollaborator
} from '@/services/projects'
import {
  createPropertyCollaborator,
  updatePropertyCollaborator
} from '@/services/properties'

export default {
  name: 'EntityCollaboratorCreateEditModal',
  props: {
    entityId: {
      type: [String, Number]
    },
    entityType: {
      type: String
    },
    propertyTransactionType: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      editMode: false,
      entityCollaboratorId: null,
      roles: [],
      values: {}
    }
  },
  computed: {
    propertyIsRental () {
      return this.propertyTransactionType === 3
    }
  },
  methods: {
    async show (collaborator = null) {
      const response = await this.fetchRoles()
      this.editMode = false
      if (collaborator) {
        this.editMode = true
        this.$set(this.values, 'collaborator', collaborator)
        this.entityCollaboratorId = collaborator.id // Looks like the "collaborator ID", but it is the PropertyCollaborator or ProjectCollaborator ID
        this.$set(this.values, 'role', collaborator.role.id)
      }
      this.$refs.modal.show()
      return response
    },
    async fetchRoles () {
      const params = { type: [1, 3] } // type 1 = ALL, type 3  = PROPERTY/PROJECT collaborator roles
      const response = await getCollaboratorRoles({ params })
      this.roles = response?.data?.results.filter(role => role.key !== 'primary_collaborator').map(role => { return { label: role.name_nl, value: role.id } })
      return response
    },
    async submit (data) {
      let response = null
      if (this.editMode) {
        response = this.entityType === 'property'
          ? await updatePropertyCollaborator(this.entityId, this.entityCollaboratorId, data)
          : await updateProjectCollaborator(this.entityId, this.entityCollaboratorId, data)
        successModal('Medewerker werd succesvol gewijzigd')
      } else {
        data.collaborator = data.collaborator.id
        response = this.entityType === 'property'
          ? await createPropertyCollaborator(this.entityId, data)
          : await createProjectCollaborator(this.entityId, data)
        successModal('Medewerker werd succesvol aangemaakt')
      }
      this.$emit('collaboratorCreatedOrUpdated')
      this.$refs.modal.hide()
      return response
    }
  }
}
</script>
