<template>
  <div class="tw-text-xs">
    <span
      v-if="showRoleLabel"
      class="tw-inline-blocktw-border tw-rounded tw-px-1 tw-bg-gray-200 tw-mb-2"
    >
      {{ getRoleDisplay(invoiceContact.role) }}
    </span>
    <div class="tw-mb-1 tw-text-sm tw-break-words tw-font-semibold">
      {{ invoiceContact.title }}
      <span v-if="parseInt(invoiceContact.invoicing_share) !== 100">
        ({{ percentage(invoiceContact.invoicing_share, 4) }})
      </span>
    </div>

    <div class="tw-my-1">{{ getInvoiceContactAddress(invoiceContact) }}</div>
    <div v-if="getInvoiceContactPrimary(invoiceContact).email">
      <i class="fa fa-envelope" />
      <Email type="to" :contact="getInvoiceContactPrimary(invoiceContact)" />
    </div>

    <div class="tw-mt-1 tw-flex tw-flex-wrap tw-gap-4">
      <router-link
        v-for="({ contact, primary }) in invoiceContact.contacts"
        :key="contact.id"
        :to="{ name: 'ContactDetails', params: { id: contact.id } }"
        target="_blank"
        :class="[
          'label hover:!tw-underline',
          primary
            ? 'tw-bg-success !tw-text-white'
            : 'tw-bg-white tw-border tw-border-success !tw-text-success'
        ]"
      >
        {{ contact.display_name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import Email from '@/components/iam/Email'
import { getInvoiceContactAddress, getInvoiceContactPrimary, percentage } from '@/utils/helpers'

export default {
  name: 'EntityInvoiceContactsDisplay',
  components: {
    Email
  },
  props: {
    invoiceContact: {
      type: Object,
      required: true
    },
    showRoleLabel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    percentage,
    getInvoiceContactAddress,
    getInvoiceContactPrimary,
    getRoleDisplay (role) {
      if (role === 1) return 'Eigenaar'
      if (role === 2) return 'Koper'
      if (role === 3) return 'Huurder'
      return ''
    }
  }
}
</script>
