<template>
  <div>
    <BaseModal
      ref="modal"
      title="Geografische informatie"
      content-padding="tw-p-4"
      max-width="tw-max-w-[85%]"
      @hide="$emit('hide')"
    >
      <EntityMap
        ref="map"
        :latitude="latitude"
        :longitude="longitude"
        :parcelString="parcelString"
      />

      <div class="tw-float-right">
        <FormulateInput
          type="button"
          title="Opslaan"
          @click="showBuildingUnits"
        >
          <i class="fa tw-mr-1 fa-arrow-right" />
          Volgende
        </FormulateInput>
      </div>
    </BaseModal>
    <BuildingUnitsModal
      ref="buildingUnitsModal"
      :entity="entity"
      :geolocation="geolocation"
      @hide="hide"
    />
  </div>
</template>

<script>
import BuildingUnitsModal from '@/components/properties/BuildingUnitsModal'
import EntityMap from '@/components/properties/EntityMap'

import { mapActions } from 'vuex'

export default {
  name: 'EntityMapModal',
  components: { BuildingUnitsModal, EntityMap },
  props: {
    entity: {
      type: Object
    },
    geolocation: {
      type: Object,
      default: () => {
        return {}
      }
    },
    parcelString: {
      type: String
    }
  },
  data () {
    return {
      baseRegistersData: {},
      buildingUnits: {},
      progressBaseRegistersDataLoad: 0
    }
  },
  computed: {
    latitude () {
      return this.geolocation.latitude
    },
    longitude () {
      return this.geolocation.longitude
    }
  },
  methods: {
    ...mapActions('properties', [
      'loadProperty',
      'loadProject'
    ]),

    show () {
      this.$refs.modal.show()
      this.$nextTick(() => {
        // Because the BaseModal uses a v-if to render the content, so we have to wait for the render to finish
        this.$refs.map.render()
      })
    },
    hide () {
      this.$nextTick(() => {
        // To prevent the modal being closed before it is focused, hence erroring out.
        this.$refs.modal.hide()
      })
    },
    focus () {
      if (!this.$refs.modal) return
      this.$refs.modal.focus()
    },
    async showBuildingUnits () {
      // Important for a parcel to be selected before fetching the building units
      const parcels = this.$refs.map.getParcels()
      const coordinates = this.$refs.map.getCoordinates()
      this.$refs.buildingUnitsModal.show(parcels, coordinates)
    }
  }
}
</script>
