<template>
  <PanelBox title="Medewerkers">
    <span slot="toolbar">
      <button
        type="button"
        class="toolbar-button"
        @click="showEntityCollaboratorCreateEditModal(null)"
      >
        <i class="fas fa-plus" /> Aanmaken
      </button>
    </span>
    <div class="tw-grid md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-4">
      <div
        v-for="collaborator in collaborators"
        :key="collaborator.id"
        class="tw-relative tw-flex tw-gap-x-3 tw-rounded tw-border tw-p-2"
      >
        <div v-if="collaborator.role.key !== 'primary_collaborator'" class="tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2">
          <button
            type="button"
            title="Bewerken"
            class="action-button tw-bg-white tw-text-success tw-border-success hover:tw-bg-success"
            @click="showEntityCollaboratorCreateEditModal(collaborator)"
          >
            <i class="fas fa-pencil" />
          </button>
          <button
            type="button"
            title="Verwijderen"
            class="action-button tw-bg-white tw-text-danger tw-border-danger hover:tw-bg-danger"
            @click="deleteCollaborator(collaborator.id)"
          >
            <i class="fas fa-trash" />
          </button>
        </div>
        <div class="tw-flex tw-justify-center tw-w-10">
          <img
            alt="user image"
            :src="collaborator.picture || require('@/assets/img/profile.png')"
            class="tw-w-10 tw-h-10 tw-rounded-full"
          />
        </div>
        <div>
          <span class="tw-font-bold">{{ collaborator.display_name }}</span>
          <br>
          <span class="tw-italic tw-text-xs">{{ collaborator.role.name_nl }}</span>
          <button
            v-if="collaborator.role.key === 'collaborator'"
            type="button"
            title="Makelaar primair maken"
            class="tw-ml-1 tw-px-1 tw-rounded-lg tw-bg-primary tw-text-white tw-font-bold tw-text-[0.5rem]"
            @click="switchPrimaryCollaborator(collaborator)"
          >
            &lt;&gt;
          </button>
        </div>
      </div>
    </div>
    <hr v-if="contactCollaborators.length"  class="tw-my-4" />
    <div class="tw-grid md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-4">
      <div
        v-for="contactCollaborator in contactCollaborators"
        :key="contactCollaborator.id"
        :class="[
          'tw-flex tw-gap-x-3 tw-rounded tw-border tw-p-2',
          isOverriddenByEntityCollaborator(contactCollaborator) ? 'tw-text-gray-400' : ''
        ]"
      >
        <div class="tw-flex tw-justify-center tw-w-10">
          <img
            alt="user image"
            :src="contactCollaborator.collaborator.picture_url || require('@/assets/img/profile.png')"
            class="tw-w-10 tw-h-10 tw-rounded-full"
          />
        </div>
        <div>
          <span class="tw-font-bold">{{ contactCollaborator.collaborator.display_name }}</span>
          <br>
          <span class="tw-italic tw-text-xs">
            {{ contactCollaborator.role.name_nl }} van <u>{{ contactCollaborator.acted_upon_contact.display_name }}</u>
          </span>
        </div>
      </div>
    </div>
    <EntityCollaboratorCreateEditModal
      ref="entityCollaboratorCreateEditModal"
      :entity-type="entityType"
      :entity-id="entityId"
      :property-transaction-type="propertyTransactionType"
      @collaboratorCreatedOrUpdated="fetchCollaborators"
    />
  </PanelBox>
</template>

<script>
import EntityCollaboratorCreateEditModal from '@/components/properties/EntityCollaboratorCreateEditModal'
import PanelBox from '@/components/iam/PanelBox'
import { questionModal, successModal } from '@/modalMessages'
import {
  getPropertyCollaborators,
  getPropertyContactCollaborators,
  deletePropertyCollaborator,
  switchPrimaryPropertyCollaborator
} from '@/services/properties'
import {
  getProjectCollaborators,
  getProjectContactCollaborators,
  deleteProjectCollaborator,
  switchPrimaryProjectCollaborator
} from '@/services/projects'

export default {
  name: 'EntityCollaboratorsPanelBox',
  components: {
    PanelBox,
    EntityCollaboratorCreateEditModal
  },
  props: {
    entityId: {
      type: [Number, String]
    },
    entityType: {
      type: [String]
    },
    propertyTransactionType: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      collaborators: [],
      contactCollaborators: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const response = await Promise.all([this.fetchCollaborators(), this.fetchContactCollaborators()])
      return response
    },
    showEntityCollaboratorCreateEditModal (collaborator = null) {
      this.$refs.entityCollaboratorCreateEditModal.show(collaborator)
    },
    isOverriddenByEntityCollaborator (contactCollaborator) {
      const contactCollaboratorRoleKey = contactCollaborator.role?.key
      const entityCollaborator = this.collaborators.find(collaborator => collaborator.role?.key === contactCollaboratorRoleKey)
      return !!entityCollaborator
    },
    async fetchCollaborators () {
      const payload = {
        params: { include_inactive: 1 },
        [this.entityType === 'property' ? 'propertyId' : 'projectId']: this.entityId
      }
      const response = this.entityType === 'property'
        ? await getPropertyCollaborators(payload)
        : await getProjectCollaborators(payload)
      this.collaborators = response.data?.results
      return response
    },
    async fetchContactCollaborators () {
      const response = this.entityType === 'property'
        ? await getPropertyContactCollaborators(this.entityId)
        : await getProjectContactCollaborators(this.entityId)
      this.contactCollaborators = response.data?.results
      return response
    },
    async deleteCollaborator (collaboratorId) {
      const response = this.entityType === 'property'
        ? await deletePropertyCollaborator(this.entityId, collaboratorId)
        : await deleteProjectCollaborator(this.entityId, collaboratorId)
      await this.fetchCollaborators()
      successModal('Medewerker is verwijderd')
      return response
    },
    async switchPrimaryCollaborator (collaborator) {
      const leadsTitle = this.entityType === 'property' ? '' : '1  - Leads'
      const leadsResult = await questionModal(`${collaborator.display_name} wordt als nieuwe primaire makelaar ingesteld. Wens je alle leads over te zetten?`, leadsTitle)
      const should_transfer_matches = leadsResult.value || false
      let should_transfer_entities = false
      if (this.entityType === 'project') {
        const entitiesResult = await questionModal(`${collaborator.display_name} wordt als nieuwe primaire makelaar ingesteld. Wens je de primaire medewerker van alle entiteiten ook aan te passen?`, '2 - Entiteiten')
        should_transfer_entities = entitiesResult.value || false
      }
      const payload = {
        new_primary_collaborator: collaborator.id,
        should_transfer_matches,
        should_transfer_entities
      }
      const response = this.entityType === 'property'
        ? await switchPrimaryPropertyCollaborator(this.entityId, payload)
        : await switchPrimaryProjectCollaborator(this.entityId, payload)
      await this.fetchCollaborators(this.propertyId)
      successModal('Nieuwe primaire makelaar is ingesteld')
      return response
    }
  }
}
</script>
